import {
  getTokenData
  // adminRoleId
} from '@/utils/helpers'

// export default {
//   isLoggedIn: !!sessionStorage.getItem("token"),
//   isInAdminRole: getTokenData().userRoleId === adminRoleId,
//   userId: getTokenData().userId,
//   userRoleId: getTokenData().userRoleId,
//   userName: getTokenData().userName,
//   userEmail: getTokenData().userEmail,
//   loading: false,
//   redirect: '',
//   enums: {},
// }

export default {
  loading: true,
  isLoggedIn: !!localStorage.getItem('token'),
  userRoleId: getTokenData().roleid,
  countries: [],
  country: getTokenData().country
}
