// import Service from '@/services'
import * as types from './mutation-types'
import router from '@/router'

export const loading = ({ commit }) => {
  commit(types.LOADING)
}

export const loaded = ({ commit }) => {
  commit(types.LOADED)
}

export const setCountries = ({ commit }, payload) => {
  commit(types.COUNTRIES, payload)
}

export const logout = async ({ commit }) => {
  // try {
  //   await Service.post('/Authentication/Logout', {})
  // } finally {
  localStorage.removeItem('token')
  localStorage.removeItem('tenantValue')
  commit(types.LOGOUT)
  await router.push({ name: 'Login' })
  // }
}
