export const loading = (state) => {
  return state.loading
}

export const isLoggedIn = (state) => {
  return state.isLoggedIn
}

export const userRoleId = state => {
  return state.userRoleId
}

export const getCountry = state => {
  return state.country
}

export const getCountries = state => {
  return state.countries
}
