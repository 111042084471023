<template>
  <!-- <v-app-bar color="primary" height="60" flat clipped-left dark>
    <v-spacer></v-spacer>
    <v-btn icon @click="logout">
      <v-icon>mdi-login-variant</v-icon>
    </v-btn>
  </v-app-bar> -->
  <v-sheet class="bar pa-4 mb-4">
    <v-row align="center">
      <v-col cols="12" md="3" lg="4">
        <h1>{{ title }}</h1>
      </v-col>
      <v-col>
        <v-row justify="end">
          <template v-for="(item, id) in items">
            <v-col cols="auto" class="mr-4" v-if="item.show" :key="id">
              <router-link :to="item.to">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">
                    {{ item.icon }}
                  </v-icon>
                  <span class="lightblue--text">
                    {{ item.title }}
                  </span>
                </div>
              </router-link>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" lg="2" v-if="(userRoleId == 100 || userRoleId == 99) && userCountry === 'Global'">
        <v-select
          :label="$t('bar.country')"
          v-model="selectedTenant"
          prepend-inner-icon="mdi-earth"
          :items="countriesList"
          item-text="name"
          item-value="name"
          rounded
          background-color="blue"
          class="bar__select"
          hide-details
          solo
          flat
          @change="setSelectedTenant"
        ></v-select>
      </v-col>
      <v-col cols="auto" class="text-right">
        <v-btn @click="logout" color="blue" outlined fab small>
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { globalAdminId, adminRoleId, managerRoleId } from '@/utils/helpers'
export default {
  name: 'SideBar',
  props: {
    title: String
  },
  data () {
    return {
      drawer: true,
      mini: false,
      countries: [],
      items: [
        // {
        //   title: 'Dashboard',
        //   icon: 'mdi-view-dashboard',
        //   to: '/'
        // },
        // {
        //   title: this.$t('bar.mobileapp'),
        //   icon: 'mdi-cellphone',
        //   to: '/',
        //   show: true
        // },
        {
          title: this.$t('summary.title'),
          icon: 'mdi-chart-box-outline',
          to: '/report',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === managerRoleId
        },
        {
          title: this.$t('shoppingCenters.shoppingCentersUAE'),
          icon: 'mdi-shopping',
          to: '/shopping-centers-uae',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId
        },
        {
          title: this.$t('users.title'),
          icon: 'mdi-account',
          to: '/users',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId
        }
      ]
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    },
    setSelectedTenant (tenant) {
      localStorage.setItem('tenantValue', tenant)
      if (tenant === 'CZ') {
        localStorage.setItem('currency', 'Kč')
      } else if (tenant === 'HU') {
        localStorage.setItem('currency', 'Ft')
      } else {
        localStorage.setItem('currency', 'zł')
      }
      location.reload()
    }
  },
  computed: {
    countriesList () {
      return this.$store.getters.getCountries
    },
    userRoleId () {
      return this.$store.getters.userRoleId
    },
    userCountry () {
      return this.$store.getters.getCountry
    },
    selectedTenant: {
      get () {
        return localStorage.getItem('tenantValue')
      },
      set (value) {
        this.setSelectedTenant(value)
      }
    }
  }
}
</script>
<style lang="scss">
.bar {
  background-color: transparent!important;
  &__select {
    color: var(--v-white-base) !important;

    & .v-input__slot {
      background-color: inherit;

      & .v-select__slot {
        background-color: inherit;

        & .v-select__selections {
          color: var(--v-white-base) !important;
        }
      }

      & .v-input__prepend-inner .v-icon,
      & .v-input__append-inner .v-icon {
        color: var(--v-white-base) !important;
      }
    }

    & .v-label,
    & .v-select__selections {
      color: var(--v-white-base) !important;
    }

    & .v-input__control {
      & .v-input__slot .v-select__slot .v-select__selections {
        color: var(--v-white-base) !important;
      }
    }
  }
}
</style>

<!-- <script>
export default {
  name: 'AppBar',
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script> -->
