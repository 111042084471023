import * as types from './mutation-types'
import router from '@/router/index'
import { getTokenData } from '@/utils/helpers'

export default {
  [types.COUNTRIES] (state, payload) {
    state.countries = payload
  },
  [types.LOADING] (state) {
    state.loading = true
  },
  [types.LOADED] (state) {
    state.loading = false
  },
  [types.LOGIN] (state) {
    state.isLoggedIn = true
    state.userRoleId = getTokenData().roleid
    state.country = getTokenData().country
  },
  [types.LOGOUT] (state) {
    state.isLoggedIn = false
    state.userRoleId = ''
    router.push({
      path: '/login'
    })
  }
}
