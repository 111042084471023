import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts/Layout'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders/OrdersWrap.vue'),
        beforeEnter: guardRoute
      },
      // {
      //   path: '',
      //   name: 'Home',
      //   component: () => import('@/views/Home'),
      //   beforeEnter: guardRoute
      // },
      {
        path: 'shopping-centers',
        name: 'ShoppingCenters',
        component: () =>
          import(/* webpackChunkName: "ShoppingCenters" */ '@/views/ShoppingCenters/ShoppingCenters.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'shopping-centers/new',
        name: 'AddShoppingCenter',
        component: () =>
          import(/* webpackChunkName: "AddShoppingCenterserList" */ '@/views/ShoppingCenters/AddShoppingCenter.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'shopping-centers/:id',
        name: 'EditShoppingCenter',
        component: () =>
          import(/* webpackChunkName: "EditShoppingCenterserList" */ '@/views/ShoppingCenters/EditShoppingCenter.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'shopping-centers-uae',
        name: 'ShoppingCentersUAE',
        component: () =>
          import(/* webpackChunkName: "ShoppingCenters" */ '@/views/UAE/ShoppingCentersUAE/ShoppingCentersUAE.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'shopping-centers-uae/new',
        name: 'AddShoppingCenterUAE',
        component: () =>
          import(
            /* webpackChunkName: "AddShoppingCenterserList" */ '@/views/UAE/ShoppingCentersUAE/AddShoppingCenterUAE.vue'
          ),
        beforeEnter: guardRoute
      },
      {
        path: 'shopping-centers-uae/:id',
        name: 'EditShoppingCenterUAE',
        component: () =>
          import(
            /* webpackChunkName: "EditShoppingCenterserList" */ '@/views/UAE/ShoppingCentersUAE/EditShoppingCenterUAE.vue'
          ),
        beforeEnter: guardRoute
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "UserList" */ '@/views/Users/Users.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'users/add',
        name: 'AddUser',
        component: () => import(/* webpackChunkName: "AddUser" */ '@/views/Users/AddUser.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'users/:id',
        name: 'EditUser',
        component: () => import(/* webpackChunkName: "AddUser" */ '@/views/Users/AddUser.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'cashiers',
        name: 'Cashiers',
        component: () => import(/* webpackChunkName: "CashierList" */ '@/views/Cashiers/Cashiers.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'cashiers/add',
        name: 'AddCashier',
        component: () => import(/* webpackChunkName: "AddCashier" */ '@/views/Cashiers/AddCashier.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'cashiers/:id',
        name: 'EditCashier',
        component: () => import(/* webpackChunkName: "AddCashier" */ '@/views/Cashiers/AddCashier.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/private-customers',
        name: 'PrivateCustomers',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Customers/PrivateCustomers.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/private-customers/:id',
        name: 'PrivateCustomer',
        component: () => import(/* webpackChunkName: "Customer" */ '@/views/Customers/PrivateCustomer.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/business-customers',
        name: 'BusinessCustomers',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Customers/BusinessCustomers.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/business-customers/:id',
        name: 'BusinessCustomer',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Customers/BusinessCustomer.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/customers-uae',
        name: 'CustomersUAE',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/UAE/CustomersUAE/CustomersUAE.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/customers-uae/:id',
        name: 'CustomerUAE',
        component: () => import(/* webpackChunkName: "Customer" */ '@/views/UAE/CustomersUAE/CustomerUAE.vue'),
        beforeEnter: guardRoute
      },
      {
        path: '/statistics-uae',
        name: 'StatisticsUAE',
        component: () => import(/* webpackChunkName: "Statistics" */ '@/views/UAE/Statistics/Statistics.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "Account" */ '@/views/Account/Account.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'delivery',
        name: 'Delivery',
        component: () => import(/* webpackChunkName: "Delivery" */ '@/views/Settings/Delivery.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'orders',
        name: 'Orders',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders/Orders.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'orders-wrap',
        name: 'OrdersWrap',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders/OrdersWrap.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'cashiers-wrap',
        name: 'CashiersWrap',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders/CashiersWrap.vue'),
        beforeEnter: guardRoute
      },
      // {
      //   path: 'cashier-orders',
      //   name: 'CashierOrders',
      //   component: () => import(/* webpackChunkName: "CashierOrders" */ '@/views/Orders/CashierOrders.vue'),
      //   beforeEnter: guardRoute
      // },
      {
        path: 'orders/:id',
        name: 'Order',
        component: () => import(/* webpackChunkName: "Order" */ '@/views/Orders/Order.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'cashier-orders/:id',
        name: 'CashierOrder',
        component: () => import(/* webpackChunkName: "CashierOrder" */ '@/views/Orders/CashierOrder.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'completed',
        name: 'Completed',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Orders/Completed.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'cashier-completed',
        name: 'CashierCompleted',
        component: () => import(/* webpackChunkName: "CashierOrders" */ '@/views/Orders/CashierCompleted.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'clickcollect',
        name: 'ClickCollect',
        component: () => import(/* webpackChunkName: "ClickCollect" */ '@/views/ClickCollect/Orders.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'clickcollect/:id',
        name: 'ClickCollectOrder',
        component: () => import(/* webpackChunkName: "Order" */ '@/views/ClickCollect/Order.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "Report" */ '@/views/Report/Report.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'stock/:id',
        name: 'Stock',
        component: () => import(/* webpackChunkName: "Stock" */ '@/views/Stock/CardsStock.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'logs',
        name: 'Logs',
        component: () => import(/* webpackChunkName: "Logs" */ '@/views/LogList/Logs.vue'),
        beforeEnter: guardRoute
      },
      {
        path: 'statute',
        name: 'StatuteList',
        component: () => import(/* webpackChunkName: "Statute" */ '@/views/Statute/StatuteList.vue'),
        beforeEnter: guardRoute
      },
      // {
      //   path: 'statute/add',
      //   name: 'AddStatute',
      //   component: () => import(/* webpackChunkName: "AddEditStatute" */ '@/views/Statute/AddEditStatute.vue'),
      //   beforeEnter: guardRoute
      // },
      {
        path: 'statute/:id',
        name: 'EditStatute',
        component: () => import(/* webpackChunkName: "AddEditStatute" */ '@/views/Statute/AddEditStatute.vue'),
        beforeEnter: guardRoute
      }
    ]
  },
  {
    path: '/',
    name: 'GlobalLayout',
    beforeEnter: guardRoute,
    component: () => import(/* webpackChunkName: "GlobalLayout" */ '@/layouts/GlobalLayout'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '@/views/Auth/Login.vue')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Auth/ResetPassword')
      },
      {
        path: 'reset-password-code',
        name: 'ResetPasswordCode',
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Auth/ResetPasswordCodeVerification')
      },
      {
        path: '/forbidden',
        component: () => import('@/views/Errors/Forbidden')
      },
      {
        path: '/notfound',
        component: () => import('@/views/Errors/Notfound')
      }
    ]
  },
  {
    path: '/',
    name: 'Global',
    component: () => import(/* webpackChunkName: "GlobalLayout" */ '@/layouts/GlobalLayout'),
    children: [
      {
        path: '/pickup/ready',
        name: 'PickUpReady',
        component: () => import(/* webpackChunkName: "pickup" */ '@/views/PickUp/Ready.vue')
      },
      {
        path: '/pickup/completed',
        name: 'PickUpCompleted',
        component: () => import(/* webpackChunkName: "pickup" */ '@/views/PickUp/Completed.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function guardRoute (to, from, next) {
  if (store.getters.isLoggedIn) {
    // if (to.path == "/login" || to.path == "/register") {
    if (to.path === '/login') {
      next({
        name: 'OrdersWrap'
      })
    }
    next()
  }
  if (!store.getters.isLoggedIn) {
    if (to.path === '/login') {
      next()
    } else {
      // store.state.redirect = to.fullPath
      next({
        name: 'Login'
      })
    }
  }
  // if (!store.getters.isLoggedIn) {
  //   // if (to.path == "/login" || to.path == "/register") {
  //   if (to.path === '/login') {
  //     next()
  //   } else {
  //     store.state.redirect = to.fullPath
  //     next({
  //       name: 'Login'
  //     })
  //   }
  // }
  next()
}

export default router
