import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pl from 'vuetify/lib/locale/pl'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#3B91E2',
        secondary: '#1e90df',
        success: '#34C759',
        accent: '#ff3624',
        error: '#b71c1c',
        white: '#ffffff',
        background: '#F4F7FE',
        blue: '#3B91E2',
        lightblue: '#7E8DBC',
        black: '#15212C'
      }
    }
  },
  lang: {
    locales: {
      pl,
      en
    },
    current: 'pl'
  }
})
