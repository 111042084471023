<template>
  <div>
    <SideBar />
    <v-container fluid>
      <v-main>
        <router-view :key="$route.path" />
      </v-main>
    </v-container>
    <notifications group="notify-group" width="350" position="top center" />
  </div>
</template>

<script>
import SideBar from './components/SideBar.vue'

export default {
  name: 'Layout',
  components: {
    SideBar
  }
}
</script>
