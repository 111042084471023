import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import i18n from './i18n'
import './styles/style.scss'
import './styles/overrides.scss'
import '@/filters/createFilters'
import { MazPhoneNumberInput } from 'maz-ui'
import VueApexCharts from 'vue-apexcharts'
import AppBar from '@/components/AppBar.vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(MazPhoneNumberInput)
Vue.use(VueApexCharts)
Vue.use(CKEditor)

Vue.component('apexchart', VueApexCharts)
Vue.component('AppBar', AppBar)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
