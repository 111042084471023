<template>
  <v-navigation-drawer
    id="default-drawer"
    color="white"
    v-model="drawer"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <div>
      <v-list-item class="mb-0 justify-space-between pa-6">
        <v-list-item-content class="">
          <v-list-item-title class="text-h2">
            <v-img contain
            height="50"
            class="ma-auto"
            src="/img/finpay-black.svg" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-2" />

      <v-list expand nav v-bind="$attrs" v-on="$listeners" class="px-6">
        <template v-for="(item, i) in items">
          <v-list-item
            v-if="item.show"
            :key="i"
            :href="item.href"
            :rel="item.href ? 'nofollow' : undefined"
            :target="item.href ? '_blank' : undefined"
            :to="item.to"
            active-class="nav__item-active"
            color="black"
            link
            class="py-1"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <v-list-item-icon
              v-if="!item.icon"
              class="
                text-caption text-uppercase
                justify-center
                ml-1
                my-2
                align-self-center
              "
            >
              {{ title }}
            </v-list-item-icon>

            <v-list-item-avatar v-if="item.avatar">
              <v-img :src="item.avatar" />
            </v-list-item-avatar>

            <v-list-item-icon v-if="item.icon" class="my-2 mr-4 align-self-center">
              <v-icon v-text="item.icon" />
            </v-list-item-icon>

            <v-list-item-content v-if="item.title">
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { globalAdminId, adminRoleId, cashierRoleId, employeeRoleId } from '@/utils/helpers'
export default {
  name: 'SideBar',
  data () {
    return {
      drawer: true,
      mini: false,
      items: [
        // {
        //   title: 'Dashboard',
        //   icon: 'mdi-view-dashboard',
        //   to: '/'
        // },
        {
          title: this.$t('orders.title'),
          icon: 'mdi-home',
          to: '/orders-wrap',
          show: this.$store.getters.userRoleId !== cashierRoleId
        },
        // {
        //   title: this.$t('completed.title'),
        //   icon: 'mdi-basket-check',
        //   to: '/completed',
        //   show: this.$store.getters.userRoleId !== cashierRoleId
        // },
        {
          title: this.$t('orders.title'),
          icon: 'mdi-basket',
          to: '/cashiers-wrap',
          show: this.$store.getters.userRoleId === cashierRoleId
        },
        // {
        //   title: this.$t('completed.title'),
        //   icon: 'mdi-basket-check',
        //   to: '/cashier-completed',
        //   show: this.$store.getters.userRoleId === cashierRoleId
        // },
        // {
        //   title: 'Click&Collect',
        //   icon: 'mdi-shopping-outline',
        //   to: '/clickcollect',
        //   show: this.$store.getters.userRoleId !== cashierRoleId
        // },
        {
          title: this.$t('shoppingCenters.title'),
          icon: 'mdi-shopping-outline',
          to: '/shopping-centers',
          show: this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === adminRoleId
        },
        // {
        //   title: this.$t('users.title'),
        //   icon: 'mdi-account-outline',
        //   to: '/users',
        //   show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId
        // },
        {
          title: this.$t('cashiers.title'),
          icon: 'mdi-account-group-outline',
          to: '/cashiers',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === employeeRoleId
        },
        {
          title: this.$t('customers.b2c'),
          icon: 'mdi-account-tie-outline',
          to: '/private-customers',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === employeeRoleId
        },
        {
          title: this.$t('customers.b2b'),
          icon: 'mdi-account-tie-outline',
          to: '/business-customers',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === employeeRoleId
        },
        // {
        //   title: this.$t('shoppingCenters.shoppingCentersUAE'),
        //   icon: 'mdi-shopping',
        //   to: '/shopping-centers-uae',
        //   show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === employeeRoleId
        // },
        {
          title: this.$t('customers.uae'),
          icon: 'mdi-account',
          to: '/customers-uae',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === employeeRoleId
        },
        {
          title: this.$t('stock.title'),
          icon: 'mdi-card-bulleted-outline',
          to: '/stock/1',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId || this.$store.getters.userRoleId === employeeRoleId
        },
        // {
        //   title: this.$t('summary.title'),
        //   icon: 'mdi-chart-box-outline',
        //   to: '/report',
        //   show: this.$store.getters.userRoleId !== cashierRoleId
        // },
        {
          title: this.$t('register.title'),
          icon: 'mdi-file-document-outline',
          to: '/logs',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId
        },
        {
          title: this.$t('account.title'),
          icon: 'mdi-account-cog-outline',
          to: '/account',
          show: this.$store.getters.userRoleId === cashierRoleId
        },
        {
          title: this.$t('delivery.title'),
          icon: 'mdi-truck',
          to: '/delivery',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId
        },
        {
          title: this.$t('statute.title'),
          icon: 'mdi-gavel',
          to: '/statute',
          show: this.$store.getters.userRoleId === adminRoleId || this.$store.getters.userRoleId === globalAdminId
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.nav {
  &__item {
    &-active {
      border-right: 3px solid  var(--v-blue-base);
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;

      &::before{
        opacity: 0!important;
      }

      & .v-list-item__icon > .v-icon {
        color: var(--v-blue-base)!important
      }
    }
  }
}
</style>
